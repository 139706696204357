import React, { useEffect, useState } from "react";
import "../assets/styles/Home.css";
import CardComponent from "../components/CardComponent";
import Carousel from "../components/Carousel";
import MapComponent from "../components/MapComponent";
import cardsData from "../libs/homeCardData";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const Home = () => {
  const [isPrevDisabled, setIsPrevDisabled] = useState(true);
  const [isNextDisabled, setIsNextDisabled] = useState(false);

  const updateButtonStates = () => {
    const container = document.querySelector(".mn_layout-container");
    if (container) {
      const { scrollLeft, scrollWidth, clientWidth } = container;
      setIsPrevDisabled(scrollLeft === 0);
      setIsNextDisabled(scrollLeft + clientWidth >= scrollWidth);
    }
  };

  useEffect(() => {
    const container = document.querySelector(".mn_layout-container");
    // Initial check
    updateButtonStates();

    // Add event listeners for scroll and resize
    container.addEventListener("scroll", updateButtonStates);
    window.addEventListener("resize", updateButtonStates);

    // Clean up
    return () => {
      container.removeEventListener("scroll", updateButtonStates);
      window.removeEventListener("resize", updateButtonStates);
    };
  }, []);

  const scrollLeft = () => {
    const container = document.querySelector(".mn_layout-container");
    if (container) {
      container.scrollBy({
        left: -container.clientWidth,
        behavior: "smooth",
      });
    }
  };

  const scrollRight = () => {
    const container = document.querySelector(".mn_layout-container");
    if (container) {
      container.scrollBy({
        left: container.clientWidth,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="container">
      <Helmet>
        <title>Inicio</title>
        <meta
          name="description"
          content="Bienvenido a la mejor academia de tennis en Villavicencio Vanguardia."
        ></meta>
      </Helmet>
      <Carousel />
      <div className="block">
        <div className="text">Academia de Tennis Muñoz</div>
      </div>
      <div className="wrapper">
        <p className="about_title_mobile">Cursos de calidad en Vanguardia</p>
        <div className="about_container">
          <div className="about_image_container">
            <iframe
              className="about_video"
              src="https://www.youtube.com/embed/comzuGbwmwM?si=mZtSvLziKeNrvyxO"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
          </div>
          <div className="about_section_text">
            <h2>Hermosas instalaciones en Vanguardia</h2>
            <p>
              Formar parte de la Academia Muñoz durante un curso de formación
              implica integrarse a una comunidad apasionada. La Academia se
              encuentra en la cuidad de Villavicencio vía Vanguardia , la cual cuenta con amplias instalaciones
              y un clima competitivo y familiar.
            </p>
            <div className="button_text">
              <Link to={"facilities"} className="btn btn--primary">
                Conoce nuestras instalaciones{" "}
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="space-large"></div>
      <div className="courses_section">
        <h2>Cursos</h2>
        <div className="mn_layout">
          <div
            className={`swiper-button-prev ${
              isPrevDisabled ? "swiper-button-disabled" : ""
            }`}
            role="button"
            aria-label="Previous slide"
            onClick={scrollLeft}
            style={{ cursor: isPrevDisabled ? "not-allowed" : "pointer" }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="41"
              height="41"
              viewBox="0 0 41 41"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23.4241 8.86889C23.8135 8.4794 24.447 8.4794 24.8365 8.86889C25.226 9.25839 25.226 9.89194 24.8365 10.2814L14.3554 20.7626L24.8365 31.2437C25.226 31.6332 25.226 32.2668 24.8365 32.6563C24.447 33.0458 23.8135 33.0458 23.4241 32.6563L12.944 22.1762C12.6545 21.8867 12.5024 21.4511 12.5024 21.0165C12.5024 20.5819 12.6545 20.1463 12.944 19.8568L23.4241 9.37667L23.4241 8.86889Z"
                fill="#fff"
              />
            </svg>
          </div>
          <div className="mn_layout-container">
            {cardsData.map((card, index) => (
              <CardComponent
                key={index}
                className="card"
                image={card.image}
                title={card.title}
                description={card.description}
                link={card.link}
              />
            ))}
          </div>
          <div
            id="nex"
            className={`swiper-button-next ${
              isNextDisabled ? "swiper-button-disabled" : ""
            }`}
            role="button"
            aria-label="Next slide"
            onClick={scrollRight}
            style={{ cursor: isNextDisabled ? "not-allowed" : "pointer" }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="41"
              height="41"
              viewBox="0 0 41 41"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.5759 32.6563C17.1865 32.2668 17.1865 31.6332 17.5759 31.2437L28.057 20.7626L17.5759 10.2814C17.1865 9.89194 17.1865 9.25839 17.5759 8.86889C17.9654 8.4794 18.5989 8.4794 18.9884 8.86889L29.4695 19.349L29.4695 19.8568C29.758 20.1463 29.9101 20.5819 29.9101 21.0165C29.9101 21.4511 29.758 21.8867 29.4695 22.1762L18.9884 32.6563L17.5759 32.6563Z"
                fill="#fff"
              />
            </svg>
          </div>
        </div>
      </div>
      <MapComponent />
    </div>
  );
};

export default Home;
