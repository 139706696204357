import { AccessTime, CalendarMonth, SportsTennis, Watch } from '@mui/icons-material';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';


const courseDetails = [
  {
    id: "competence",
    imageSrc: "https://academy-mu-oz-backend-production.up.railway.app/public/images/dyg.gif",
    title: "Competencia",
    subtitle: "Trabajo físico técnico a alta intensidad",
    description: "El curso de competencia impartido por el profesor Edgar Muñoz comprende entrenamiento físico intenso, trabajo técnico, táctico y mental.",
    details: [
      {
        icon: <SportsTennis />,
        label: "IMPARTIDO POR",
        content: "• Edgar Muñoz"
      },
      {
        icon: <CalendarMonth />,
        label: "DÍAS DE ENTRENAMIENTO",
        content: "• De lunes a viernes"
      },
      {
        icon: <Watch />,
        label: "HORARIO DE ENTRENAMIENTO",
        content: "• De 4pm a 6pm"
      },
      {
        icon: <AccessTime />,
        label: "TIEMPO DE ENTRENAMIENTO",
        content: "• Tenis y entrenamiento físico: 2 h/día"
      },
      {
        icon: <AssignmentIndIcon />,
        label: "REQUISITOS",
        content: "• A partir de los 12 años y con participación en torneos nacionales o competiciones de alto rendimiento."
      }
    ],
    buttonText: "Solicita más información",
    buttonLink: "https://api.whatsapp.com/send/?phone=+573202421994&text=Hola%2C+estoy+interesado+en+inscribirme+al+curso+de+Competencia.&type=phone_number&app_absent=0"
  },
  {
    id: "individual-programs",
    imageSrc:"https://academy-mu-oz-backend-production.up.railway.app/public/images/pp.gif",
    title: "Programas Individuales",
    subtitle: "Entrenamiento personalizado para aspectos tácticos y técnicos",
    description: "Los programas individuales están diseñados para ofrecer un entrenamiento personalizado y adaptado a tus necesidades. Impartidos por los profesores Edgar Muñoz y Jhonatán Gómez, estos programas se enfocan en trabajar aspectos tácticos y técnicos del tenis de manera individualizada. El horario y la duración del entrenamiento son flexibles y se acordarán directamente con el entrenador para ajustarse a tus disponibilidades y objetivos.",
    details: [
      {
        icon: <SportsTennis />,
        label: "IMPARTIDO POR",
        content: "• Profesor Edgar Muñoz y Jhonatán Gómez"
      },
      {
        icon: <CalendarMonth />,
        label: "DÍAS DE ENTRENAMIENTO",
        content: "• A convenir con el entrenador"
      },
      {
        icon: <Watch />,
        label: "HORARIO DE ENTRENAMIENTO",
        content: "• A convenir con el entrenador"
      },
      {
        icon: <AccessTime />,
        label: "TIEMPO DE ENTRENAMIENTO",
        content: "• Duración flexible, a convenir con el entrenador"
      }
    ],
    buttonText: "Solicita más información",
    buttonLink: "https://api.whatsapp.com/send/?phone=+573202421994&text=Hola%2C+estoy+interesado+en+solicitar+más+información+sobre+los+Programas+Individuales.&type=phone_number&app_absent=0"
  },
  {
    id: "group-initiation",
    imageSrc:"https://academy-mu-oz-backend-production.up.railway.app/public/images/init.gif",
    title: "Grupo Iniciación",
    subtitle: "Introducción al tenis en grupo",
    description: "El curso de Grupo Iniciación está diseñado para aquellos que están comenzando en el tenis y desean aprender en un entorno grupal. Impartido por el profesor Jhonatán Gómez, el curso se enfoca en desarrollar habilidades básicas, técnicas fundamentales y fundamentos del juego en un grupo dinámico y motivador. El horario es de 3pm a 5pm, ofreciendo un espacio ideal para que los nuevos jugadores se familiaricen con el deporte y mejoren su técnica en compañía de otros principiantes.",
    details: [
      {
        icon: <SportsTennis />,
        label: "IMPARTIDO POR",
        content: "• Profesor Jhonatán Gómez"
      },
      {
        icon: <CalendarMonth />,
        label: "DÍAS DE ENTRENAMIENTO",
        content: "• De lunes a viernes"
      },
      {
        icon: <Watch />,
        label: "HORARIO DE ENTRENAMIENTO",
        content: "• De 3pm a 5pm"
      },
      {
        icon: <AccessTime />,
        label: "TIEMPO DE ENTRENAMIENTO",
        content: "• 2 h/día"
      },
      {
        icon: <AssignmentIndIcon />,
        label: "REQUISITOS",
        content: "• A partir de los 8 años en adelante"
      }
    ],
    buttonText: "Solicita más información",
    buttonLink: "https://api.whatsapp.com/send/?phone=+573202421994&text=Hola%2C+estoy+interesado+en+unirme+al+Grupo+Iniciación.&type=phone_number&app_absent=0"
  },
  {
    id: "children-formation",
    imageSrc:"https://academy-mu-oz-backend-production.up.railway.app/public/images/sne.png",
    title: "Semillero Infantil",
    subtitle: "Introducción al tenis para los más jóvenes",
    description: "El curso de Semillero Infantil está diseñado para introducir a los niños en el tenis de manera divertida y educativa. Impartido por el profesor Jhonatán Gómez, el curso se enfoca en desarrollar habilidades básicas, coordinación y amor por el deporte en un ambiente amigable y estimulante. Las sesiones se llevan a cabo de 3pm a 4pm, con actividades diseñadas para mantener a los niños comprometidos y entusiastas mientras aprenden los fundamentos del tenis.",
    details: [
      {
        icon: <SportsTennis />,
        label: "IMPARTIDO POR",
        content: "• Profesor Jhonatán Gómez"
      },
      {
        icon: <CalendarMonth />,
        label: "DÍAS DE ENTRENAMIENTO",
        content: "• De lunes a viernes"
      },
      {
        icon: <Watch />,
        label: "HORARIO DE ENTRENAMIENTO",
        content: "• De 3pm a 4pm"
      },
      {
        icon: <AccessTime />,
        label: "TIEMPO DE ENTRENAMIENTO",
        content: "• 1 h/día"
      },
      {
        icon: <AssignmentIndIcon />,
        label: "REQUISITOS",
        content: "• A partir de los 4 años en adelante"
      }
    ],
    buttonText: "Solicita más información",
    buttonLink: "https://api.whatsapp.com/send/?phone=+573202421994&text=Hola%2C+estoy+interesado+en+inscribir+a+mi+hijo+en+el+curso+de+Formación+Infantil.&type=phone_number&app_absent=0"
  }
]

export default courseDetails;
