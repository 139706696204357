import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../assets/styles/Carousel.css';

const PrevArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div
      className={`${className} custom-arrow custom-prev`}
      onClick={onClick}
    />
  );
};

const NextArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div
      className={`${className} custom-arrow custom-next`}
      onClick={onClick}
    />
  );
};

const Carousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <Slider {...settings} className="carousel">
      <div className="carousel-slide">
        <video
          width="100%"
          height="100%"
          controls
          autoPlay
          muted
        >
          <source src="https://academy-mu-oz-backend-production.up.railway.app/public/images/academy.mp4" type="video/mp4" />
          Tu navegador no soporta la etiqueta de video.
        </video>
      </div>
      <div className="carousel-slide">
        <img src={"https://academy-mu-oz-backend-production.up.railway.app/public/images/dmt3.jpg"} alt="Slide 1" />
      </div>
      <div className="carousel-slide">
        <img src="https://academy-mu-oz-backend-production.up.railway.app/public/images/dmt2.jpg" alt="Slide 2" />
      </div>
    </Slider>
  );
};

export default Carousel;
