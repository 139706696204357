import React from 'react';
import '../assets/styles/Footer.css';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';


const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section sponsors">
          <h2>Patrocinadores</h2>
          <div className="sponsors-logos">
            {/* Puedes descomentar las siguientes líneas y agregar los logos de tus patrocinadores */}
            <img src="https://academy-mu-oz-backend-production.up.railway.app/public/images/ma1.png" alt="Match Tennis" />
            {/* <img src={sponsor2Logo} alt="Patrocinador 2" /> */}
            {/* <img src={sponsor3Logo} alt="Patrocinador 3" /> */}
          </div>
        </div>

        

        <div className="footer-section academy-logo">
          <img src="https://academy-mu-oz-backend-production.up.railway.app/public/images/logo3.png" alt="Logo de la Academia" />
        </div>

        <div className="footer-section social-media">
          <a href="https://www.facebook.com/edgarmuca23?locale=es_LA" target="_blank" rel="noopener noreferrer" className="social-icon">
            <FacebookIcon fontSize="large" />
          </a>
          <a href="https://academy-mu-oz-backend-production.up.railway.app/public/images/logo3.png" target="_blank" rel="noopener noreferrer" className="social-icon">
            <InstagramIcon fontSize="large" />
          </a>
        </div>

        <div className="footer-section contact">
          <p>Contacto: 3202421994</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
