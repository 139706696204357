const cardsData = [
    {
      image: "https://academy-mu-oz-backend-production.up.railway.app/public/images/vt.png",
      title: "Competencia",
      link:'/courses#competence',
      description:
        "El Club de Tennis Muñoz ofrece un curso de competencia de tenis de alto rendimiento, diseñado para llevar tus habilidades al siguiente nivel. ¡Únete a nosotros y perfecciona tu técnica y rendimiento físico con profesores de alta calidad!",
    },
    {
      image: "https://academy-mu-oz-backend-production.up.railway.app/public/images/id.png",
      link:'/courses#individual-programs',
      title: "Programas Individuales",
      description:
        "Lleva tu juego de tenis al siguiente nivel con nuestros Programas Individuales. Estos programas están diseñados para ofrecer una atención personalizada y un enfoque específico en el desarrollo de habilidades, ideales para jugadores que buscan mejorar su rendimiento de manera más intensiva y adaptada a sus necesidades.",
    },
    {
      image: "https://academy-mu-oz-backend-production.up.railway.app/public/images/ml.png",
      title: "Grupo Semillero",
      link:'/courses#group-initiation',
      description:
        "¡Descubre el emocionante mundo del tenis con nuestros Grupos de Semillero! Diseñados para aquellos que están dando sus primeros pasos en el deporte, nuestros grupos de iniciación proporcionan una introducción amigable y accesible al tenis, perfecta para principiantes de todas las edades.",
    },
    {
      image: "https://academy-mu-oz-backend-production.up.railway.app/public/images/IMG_4682.jpeg",
      title: "Grupos de Formación Infantil",
      link:'/courses#children-formation',
      description:
        "¡Bienvenidos a nuestros Grupos de Formación Infantil! Diseñados especialmente para los pequeños que están comenzando su aventura en el mundo del tenis, nuestros grupos de formación infantil ofrecen una experiencia educativa y divertida para niños de 4 a 8 años.",
    }
  ]

  export default cardsData