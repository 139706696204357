import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const CourseCard = ({ id, imageSrc, title, subtitle, description, details, buttonText, buttonLink }) => {
  return (
    <li className="courses__image__content" id={id}>
      <div className="courses_middle">
        <div className="courses__image-content__img">
          <img src={imageSrc} alt={title} />
        </div>
        <div className="courses__text__content">
          <h2>{title}</h2>
          <h3>{subtitle}</h3>
          <div className="description_content">
            <p>{description}</p>
            {details.map((detail, index) => (
              <p key={index}>
                <strong>
                  <span style={{ color: "#0000ee" }}>
                    <b>
                      {detail.icon}
                      <strong className="color-blue"> {detail.label}</strong>
                    </b>
                  </span>
                </strong>
                <br />
                {detail.content}
              </p>
            ))}
          </div>
          <div className="course_button">
            {/* Link styled as a button */}
            <Link to={buttonLink} className="button_link">
              {buttonText}
            </Link>
          </div>
        </div>
      </div>
    </li>
  );
};

CourseCard.propTypes = {
  id: PropTypes.string.isRequired,
  imageSrc: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  details: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.element.isRequired,
      label: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
    })
  ).isRequired,
  buttonText: PropTypes.string.isRequired,
  buttonLink: PropTypes.string.isRequired,
};

export default CourseCard;
