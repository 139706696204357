import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "../assets/styles/Navbar.css";

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();

  const toggleMenu = () => {
    setMenuOpen(!menuOpen); //
  };

  return (
    <div className={`mn_header ${menuOpen ? "menu-active" : ""}`}>
      <div className="mn_header-primary">
        <div className="wrapper">
          <div className="section">
            <div className="logo">
              <Link to="/">
                <img src="https://academy-mu-oz-backend-production.up.railway.app/public/images/logo3.png" alt="logo" className="logo-image" />
              </Link>
            </div>
            <div className="hamburger-menu" onClick={toggleMenu}>
              <span></span>
              <span></span>
              <span></span>
            </div>
            <div className="navigation">
              <ul>
                <li className={`navigation-item nav-link__element ${location.pathname === "/" ? "active" : ""}`}>
                  <Link to="/" onClick={toggleMenu}>Home</Link>
                </li>
                <li className={`navigation-item nav-link__element ${location.pathname === "/courses" ? "active" : ""}`}>
                  <Link to="/courses" onClick={toggleMenu}>Cursos</Link>
                </li>
                <li className={`navigation-item nav-link__element ${location.pathname === "/facilities" ? "active" : ""}`}>
                  <Link to="/facilities" onClick={toggleMenu}>Instalaciones</Link>
                </li>
                <li className={`navigation-item nav-link__element ${location.pathname === "/ranking" ? "active" : ""}`}>
                  <Link to="/ranking" onClick={toggleMenu}>Ranking</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
