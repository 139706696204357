import React from "react";
import "../assets/styles/Facilities.css";
import SliderComponent from "../components/SliderComponent";
// import MapComponent from "../components/MapComponent";
import FacilitiesComponent from "../components/FacilitiesComponent";
import { Helmet } from "react-helmet";

const Facilities = () => {
  return (
    <div className="facilities_wrapper">
      <Helmet>
        <title>Instalaciones - Academia Muñoz</title>
        <meta
          name="description"
          content="Explora las instalaciones de primera clase de la Academia Muñoz en Villavicencio. Ofrecemos un gimnasio moderno, canchas de tenis y mucho más para tu comodidad."
        />
      </Helmet>
      <FacilitiesComponent
        title={"Instalaciones"}
        img="https://academy-mu-oz-backend-production.up.railway.app/public/images/dmt3.jpg"
        alt={"initial"}
      />
      <SliderComponent />
      {/* <MapComponent /> */}
    </div>
  );
};

export default Facilities;
