import React from "react";
import { Helmet } from 'react-helmet';
import FacilitiesComponent from "../components/FacilitiesComponent";
import useRankings from "../hooks/useRankings";
import AccountCircleIcon from "@mui/icons-material/AccountCircle"; // Importamos el icono
import CircularProgress from "@mui/material/CircularProgress"; // Importa el spinner
import "../assets/styles/Ranking.css";

const Ranking = () => {
  const { rankings, loading } = useRankings();

  if (loading) {
    return (
      <>
        <FacilitiesComponent
          img={
            "https://s1.elespanol.com/2024/07/12/actualidad/869923724_247740490_1706x960.jpg"
          }
          alt={"ranking"}
          title={"Ranking"}
        />
        <div className="spinner-container">
          <CircularProgress />
        </div>
      </>
    );
  }

  // if (error) {
  //   return <div>Error: {error.message}</div>;
  // }

  // Mapear las categorías según el criterio de sencillos, género y edad
  const categorias = {
    "sencillos:1": { gender: "femenino", age: "12 años", matches: [] },
    "sencillos:2": { gender: "masculino", age: "12 años", matches: [] },
    "sencillos:3": { gender: "femenino", age: "14 años", matches: [] },
    "sencillos:4": { gender: "masculino", age: "14 años", matches: [] },
    "sencillos:32": { gender: "femenino", age: "18 años", matches: [] },
    "sencillos:33": { gender: "masculino", age: "18 años", matches: [] },
  };

  // Asumir que rankings tiene una estructura con esta información
  Object.keys(rankings).forEach((category) => {
    if (categorias[category]) {
      categorias[category].matches = rankings[category];
    }
  });

  return (
    <div>
      <Helmet>
        <title>Ranking de Jugadores - Academia Muñoz</title>
        <meta
          name="description"
          content="Consulta el ranking de los mejores jugadores en la Academia Muñoz. Descubre el desempeño y las posiciones de los jugadores en nuestra academia en Villavicencio."
        />
      </Helmet>
      <FacilitiesComponent
        img={
          "https://s1.elespanol.com/2024/07/12/actualidad/869923724_247740490_1706x960.jpg"
        }
        alt={"ranking"}
        title={"Ranking"}
      />
      <div className="wrapper">
        <div className="ranking_container">
          {Object.keys(categorias).map((category) => (
            <div key={category} className={`ranking_${category}`}>
              <h3>{`Categoría: (${categorias[category].gender} - ${categorias[category].age})`}</h3>
              <table>
                <thead>
                  <tr>
                    <th>Ranking Academia</th>
                    <th>Ranking Col Fed</th>
                    <th>Jugador</th>
                    <th>WTN</th>
                    <th>Puntos Singles</th>
                    <th>Puntos Dobles</th>
                  </tr>
                </thead>
                <tbody>
                  {categorias[category].matches.map((player) => (
                    <tr key={player.code}>
                      <td>{player.academyRanking}</td>
                      <td>{player.fedRanking}</td>
                      <td>
                        <div className="player-info">
                          <AccountCircleIcon
                            style={{ color: "#051224", fontSize: "40px" }}
                          />
                          <span>{`${player.name} ${player.lastName}`}</span>
                        </div>
                      </td>
                      <td>{player.wtn}</td>
                      <td>{player.points}</td>
                      <td>{player.doubles}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Ranking;
