import { useState, useEffect } from "react"
import axios from "axios"

const useRankings = () => {
  const [rankings, setRankings] = useState({})
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    const fetchRankings = async () => {
      try {
        const response = await axios.get("https://academy-mu-oz-backend-production.up.railway.app/api/ranking")
        setRankings(response.data)
        setLoading(false)
      } catch (err) {
        setError(err)
        setLoading(false)
      }
    };

    fetchRankings()
  }, []);

  return { rankings, loading, error }
}

export default useRankings;
