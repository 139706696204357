import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Navbar from "./components/Navbar";
import Courses from "./pages/Courses";
import Footer from "./components/Footer";
import Facilities from "./pages/Facilities";
import ScrollToTop from "./components/ScrollToTop";
import Ranking from "./pages/Ranking";

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/courses" element={<Courses />} />
        <Route path="/facilities" element={<Facilities />} />
        <Route path="/ranking" element={<Ranking />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
