import React, { useEffect } from "react";
import "../assets/styles/Courses.css";
import CourseCard from "../components/CourseCard";
import FacilitiesComponent from "../components/FacilitiesComponent";
import courseDetails from "../libs/coursesData";
import { Helmet } from 'react-helmet';

const Courses = () => {
  useEffect(() => {
    const scrollToElement = (id) => {
      const element = document.getElementById(id);
      if (element) {
        const yOffset = -120;
        const y =
          element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    };

    const hash = window.location.hash.replace("#", "");
    if (hash) {
      scrollToElement(hash);
    }
  }, []);

  return (
    <div>
      <Helmet>
        <title>Cursos de Tenis - Academia Muñoz</title>
        <meta
          name="description"
          content="Descubre los mejores cursos de tenis en Villavicencio ofrecidos por la Academia Muñoz. Aprende y mejora tus habilidades con nuestros entrenadores profesionales."
        />
      </Helmet>
      <FacilitiesComponent
        img="https://s1.elespanol.com/2019/12/26/actualidad/actualidad_454965059_141211973_1706x960.jpg"
        alt={"trn"}
        description={"Cursos para alcanzar tu mejor rendimiento"}
        title={"Cursos"}
      />
      <main className="courses_template">
        <ul className="courses__list">
          {courseDetails.map((course) => (
            <CourseCard
              key={course.id}
              id={course.id}
              imageSrc={course.imageSrc}
              title={course.title}
              subtitle={course.subtitle}
              description={course.description}
              details={course.details}
              buttonText={course.buttonText}
              buttonLink={course.buttonLink}
            />
          ))}
        </ul>
      </main>
    </div>
  );
};

export default Courses;
