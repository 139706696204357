import React, { useCallback, useEffect, useRef, useState } from "react";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

const SliderComponent = React.memo(() => {
  const swiperRef = useRef(null);
  const [imagesPerPage, setImagesPerPage] = useState(window.innerWidth <= 711 ? 1 : 2);
  const [buttonStates, setButtonStates] = useState({
    isNextDisabled: false,
    isPrevDisabled: true,
  });

  const updateButtonStates = useCallback(() => {
    if (swiperRef.current && swiperRef.current.swiper) {
      const swiper = swiperRef.current.swiper;
      setButtonStates({
        isNextDisabled: swiper.isEnd,
        isPrevDisabled: swiper.isBeginning,
      });
    }
  }, []);

  const handleResize = useCallback(() => {
    const isMobile = window.innerWidth <= 711;
    setImagesPerPage(isMobile ? 1 : 2);
  }, []);

  useEffect(() => {
    handleResize(); // Initial call to set the correct imagesPerPage
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [handleResize]);

  const images = [
    "https://res.cloudinary.com/dd0jxklpe/image/upload/fl_preserve_transparency/v1724024204/IMG_4654_zbt4ty.jpg?_s=public-apps",
    "https://res.cloudinary.com/dd0jxklpe/image/upload/fl_preserve_transparency/v1724024203/IMG_4529_bfopce.jpg?_s=public-apps",
    "https://res.cloudinary.com/dd0jxklpe/image/upload/fl_preserve_transparency/v1724024219/IMG_4655_jarkt6.jpg?_s=public-apps",
    "https://res.cloudinary.com/dd0jxklpe/image/upload/fl_preserve_transparency/v1724024217/IMG_4656_xexecz.jpg?_s=public-apps",
    "https://academy-mu-oz-backend-production.up.railway.app/public/images/IMG_4529.jpeg",
  ];

  return (
    <div className="gym_wrapper">
      <div className="gym_container">
        <div className="gym_content_container">
          <div className="gym_content_text">
            <h2 className="gym_text_h2">
              Instalaciones de calidad excepcional
            </h2>
            <p className="gym_text_p">
              El Club Muñoz cuenta con instalaciones amplias diseñadas para
              ofrecerte la mejor experiencia. Nuestro club incluye un gimnasio
              moderno, un área social acogedora, un frontón de tenis y cuatro
              canchas de tenis, todo pensado para tu comodidad y disfrute. Ven y
              descubre todo lo que tenemos para ofrecerte.
            </p>
          </div>
          <div className="gym_content_image_card">
            <Swiper
              ref={swiperRef}
              slidesPerView={imagesPerPage}
              pagination={{ clickable: true }}
              navigation={{
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
              }}
              onSlideChangeTransitionEnd={updateButtonStates}
              modules={[Navigation, Pagination]}
            >
              {images.map((image, index) => (
                <SwiperSlide key={index}>
                  <img
                    decoding="async"
                    loading="lazy"
                    className="card_gym_img"
                    style={{ width: "339.565px", marginRight: "30px" }}
                    src={image}
                    alt={`Slide ${index + 1}`}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
            <div
              className={`swiper-button-prev ${
                buttonStates.isPrevDisabled ? "swiper-button-disabled" : ""
              }`}
              role="button"
              aria-label="Previous slide"
              onClick={() => swiperRef.current.swiper.slidePrev()}
              style={{ cursor: buttonStates.isPrevDisabled ? "not-allowed" : "pointer" }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="41"
                height="41"
                viewBox="0 0 41 41"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M23.4241 8.86889C23.8135 8.4794 24.447 8.4794 24.8365 8.86889C25.226 9.25839 25.226 9.89194 24.8365 10.2814L14.3554 20.7626L24.8365 31.2437C25.226 31.6332 25.226 32.2668 24.8365 32.6563C24.447 33.0458 23.8135 33.0458 23.4241 32.6563L12.944 22.1762C12.6545 21.8867 12.5024 21.4511 12.5024 21.0165C12.5024 20.5819 12.6545 20.1463 12.944 19.8568L23.4241 9.37667L23.4241 8.86889Z"
                  fill="#fff"
                />
              </svg>
            </div>
            <div
              className={`swiper-button-next ${
                buttonStates.isNextDisabled ? "swiper-button-disabled" : ""
              }`}
              role="button"
              aria-label="Next slide"
              onClick={() => swiperRef.current.swiper.slideNext()}
              style={{ cursor: buttonStates.isNextDisabled ? "not-allowed" : "pointer" }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="41"
                height="41"
                viewBox="0 0 41 41"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M17.5759 32.6563C17.1865 32.2668 17.1865 31.6332 17.5759 31.2437L28.057 20.7626L17.5759 10.2814C17.1865 9.89194 17.1865 9.25839 17.5759 8.86889C17.9654 8.4794 18.5989 8.4794 18.9884 8.86889L29.4695 19.349L29.4695 19.8568C29.758 20.1463 29.9101 20.5819 29.9101 21.0165C29.9101 21.4511 29.758 21.8867 29.4695 22.1762L18.9884 32.6563L17.5759 32.6563Z"
                  fill="#fff"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default SliderComponent;
